import { ButtonStyle, Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'
import { Dictionary, PageDataType } from 'typings/shared'
import { Request } from '@services/models'
import moment from 'moment'
import { LanguageEnum } from '@services/constants'

export type Props = {
	pageAssets: Dictionary
	request: Request
	requests: PageDataType['requests']
	language: LanguageEnum
}

type classes = {
	paragraph: string
	h4: string
	h5: string
	listMargin: string
	bottomMargin: string
	viewDetailsSpan: string
	title: string
	btn: string
	button: string
	buttonOutline: string
	collapse: string
	summarySubTitles: string
	answerMargin: string
}

export const classes: classes = makeClasses({
	paragraph: {
		margin: '0px 0px 15px',
		padding: '0px',
		fontFamily: '"Roboto", Arial, Helvetica, sans-serif',
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '25px'
	},
	h4: {
		fontWeight: '600',
		marginBottom: '20px',
		margin: '20px 0px 5px',
		padding: '0px',
		fontSize: '20px',
		lineHeight: '22px',
		color: Colors.lightBlack
	},
	h5: {
		fontSize: '20px',
		fontWeight: '600',
		margin: '20px 0 20px 0',
		color: Colors.secondary
	},
	listMargin: {
		marginTop: '1px',
		marginBottom: '1px',
		'& p': {
			margin: 0
		}
	},
	bottomMargin: {
		marginBottom: '20px'
	},
	viewDetailsSpan: {
		color: Colors.secondary,
		textDecoration: 'underline',
		cursor: 'pointer'
	},
	title: {
		color: Colors.dark,
		fontSize: '14px',
		fontWeight: 700,
		lineHeight: '20px'
	},
	btn: {
		...ButtonStyle,
		'&:hover': {
			background: Colors.primary
		}
	},

	button: {
		cursor: 'pointer',
		padding: '15px 30px',
		fontSize: '16px',
		marginLeft: '40px',
		height: '48px'
	},
	buttonOutline: {
		backgroundColor: 'transparent',
		border: `1px solid ${Colors.secondary}`,
		color: `${Colors.secondary}`
	},
	collapse: {
		'& > div[id^="collapse-"i]': {
			borderBottom: `1px solid ${Colors.grey}`,
			':first-of-type': {
				borderTop: `1px solid ${Colors.grey}`
			}
		}
	},
	summarySubTitles: {
		'& h5': {
			fontSize: '18px !important',
			margin: '10px 0px !important'
		},
		'& h6': {
			fontSize: '17px !important',
			margin: '10px 0px !important'
		}
	},
	answerMargin: {
		marginBottom: '5px',
		'& > strong > p': {
			margin: 0
		}
	}
})

export const getLocaleDate = (value?: string) => {
	if (value) {
		return moment(value).utc().format('DD/MM/YYYY')
	}
	return null
}

export const removeApartmentNumber = (address?: string, apartment?: string) => {
	return address?.replace(`${apartment}-`, '')
}

export const removePostalCode = (address?: string) => {
	const regex = /\s[A-Z]\d[A-Z] \d[A-Z]\d$/
	return address?.replace(regex, '')
}
