import React from 'react'
import { getRequestName } from '@pages/auth/requests/__[id].utils'
import ClothDiapersFormDetails from './clothDiapersFormDetails'
import RainwaterBarrelDetails from './rainwaterFormDetails'
import { Props } from './__details.utils'
import Notification from '@components/ui/notification'
import ReplacementOilHeatingSystemDetails from './replacementOilHeatingSystemDetails'
import TreePlantingFormDetails from './treePlantingFormDetails'
import CrackedHousesFormDetails from './crackedHousesFormDetails'
import HeritageBuildingsRevitalizationProgramDetails from './heritageBuildingsRevitalizationProgramDetails'
import ClaimNoticeFormDetails from './claimNoticeFormDetails'
import BuildingTransactionRequestFormDetails from './buildingTransactionRequestDetails'
import TemporaryPesticideApplicationPermitForIndividualsDetails from './temporaryPesticideApplicationPermitForIndividualsDetails'
import PublicAuctionsFormDetails from './publicAuctionsFormDetails'
import OilHeatingDeclarationFormDetails from './oilHeatingDeclarationFormDetails'
import HistoricalArchiveFormDetails from './historicalArchivesFormDetails'
import MunicipalEvaluationFormDetails from './municipalEvaluationFormDetails'
import AnnualRegistrationCertificateForContractorsFormDetails from './annualRegistrationCertificateForContractorsFormDetails'
import HydraulicAndPressureTestsFormDetails from './hydraulicAndPressureTestsDetails'
import FireHydrantsTempAqueductNetworkFormDetails from './FireHydrantsTempAqueductNetworkFormDetails'
import FireHydrantsTankFillingFormDetails from './FireHydrantsTankFillingFormDetails'
import AnnualPermitAndTagForAnimalDetails from './annualPermitAndTagForAnimalsDetails'
import FireplaceDeclarationFormDetails from './fireplaceDeclarationFormDetails'
import SustainableHygieneProductsFormDetails from './sustainableHygieneProductsFormDetails'
import PermitOccupationOfPublicHighwayDetails from './permitOccupationOfPublicHighwayDetails'
import BikeSharingFormDetails from './bikeSharingFormDetails'
import CarSharingFormDetails from './carSharingFormsDetails'
import FireplaceGrantFormDetails from './fireplaceGrantFormDetails'
import ParkingPermitFormDetails from './parkingPermitFormDetails'
import SelfReliefWaterCounterFormDetails from './selfReliefWaterCounterFormDetails'
import swimmingPoolSpaPermitFormDetails from './swimmingPoolSpaPermitFormDetails'
import BuildingConstructionOrAdditionPermitFormDetails from './buildingConstructionOrAdditionPermitFormDetails'

const IncidentFormDetails: React.FC<Props> = ({
	pageAssets,
	requests,
	request,
	language
}) => {
	const componentFactory = {
		rainwaterBarrel: RainwaterBarrelDetails,
		clothDiapers: ClothDiapersFormDetails,
		replacementOilHeatingSystem: ReplacementOilHeatingSystemDetails,
		crackedHouses: CrackedHousesFormDetails,
		treePlanting: TreePlantingFormDetails,
		heritageBuildingsRevitalizationProgram:
			HeritageBuildingsRevitalizationProgramDetails,
		claimNotice: ClaimNoticeFormDetails,
		buildingTransactionRequest: BuildingTransactionRequestFormDetails,
		temporaryPesticideApplicationPermitForIndividuals:
			TemporaryPesticideApplicationPermitForIndividualsDetails,
		publicAuctions: PublicAuctionsFormDetails,
		oilHeatingDeclaration: OilHeatingDeclarationFormDetails,
		historicalArchive: HistoricalArchiveFormDetails,
		municipalEvaluation: MunicipalEvaluationFormDetails,
		annualRegistrationCertificateForContractors:
			AnnualRegistrationCertificateForContractorsFormDetails,
		hydraulicAndPressureTests: HydraulicAndPressureTestsFormDetails,
		fireHydrantsTempAqueductNetwork: FireHydrantsTempAqueductNetworkFormDetails,
		fireHydrantsTankFilling: FireHydrantsTankFillingFormDetails,
		fireplaceDeclaration: FireplaceDeclarationFormDetails,
		annualPermitAndTagForAnimals: AnnualPermitAndTagForAnimalDetails,
		sustainableHygieneProducts: SustainableHygieneProductsFormDetails,
		permitOccupationOfPublicHighway: PermitOccupationOfPublicHighwayDetails,
		carSharing: CarSharingFormDetails,
		bikeSharing: BikeSharingFormDetails,
		fireplaceGrant: FireplaceGrantFormDetails,
		parkingPermit: ParkingPermitFormDetails,
		selfReliefWaterCounter: SelfReliefWaterCounterFormDetails,
		swimmingPoolSpaPermit: swimmingPoolSpaPermitFormDetails,
		buildingConstructionOrAdditionPermit: BuildingConstructionOrAdditionPermitFormDetails
	}

	const DetailsComponent = componentFactory[getRequestName(requests, request)!]

	if (DetailsComponent == undefined) {
		return (
			<div>
				<Notification
					text={'Request details consultation component NOT available yet.'}
					type="error"
				/>
			</div>
		)
	}

	return (
		<DetailsComponent
			pageAssets={pageAssets}
			request={request}
			language={language}
		/>
	)
}

export default IncidentFormDetails
